import React from 'react';
import {Button, ButtonProps} from "@chakra-ui/react";


export interface PrimaryButtonProps extends ButtonProps {
}

export function PrimaryButton(props: PrimaryButtonProps) {
  const btnProps = props as ButtonProps;
  return <Button
    mt={5}
    w="100%"
    bg="blue.800"
    _hover={{bg: 'blue.700'}}
    color="white" size="lg"
    {...btnProps}>
    {props.children}
  </Button>
}
