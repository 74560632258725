import {ActividadCardProps} from "../types/actividades";
import {
    Button,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {isFetchingActividadesSelector} from "../selectors/actividadesSelector";
import {faCalendarCheck, faCalendarTimes} from "@fortawesome/free-solid-svg-icons";
import {getUnixTime} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {checkIn, checkOut} from "../slices/actividadesSlice";
import React from "react";

/**
 * Modal de confirmacion para el inicio y fin de jornada, tambien incluye el boton que abre el modal
 * @param props
 * @constructor
 */
export function ConfirmCheckInOutModal(props: ActividadCardProps) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const dispatch = useDispatch();
    const isFetching = useSelector(isFetchingActividadesSelector);

    let textBtn: string;
    let icon;
    let bgColor: string;
    let hoverColor: string;
    let handleClick;

    const currentTime = getUnixTime(new Date());

    if ((currentTime >= props.scheduledStart && currentTime < props.scheduledEnd) &&
        props.start == null && props.end == null) {
        bgColor = "green.500";
        hoverColor= "green.400";
        textBtn = "Iniciar";
        icon = faCalendarCheck;
        handleClick = () => {
            dispatch(checkIn(props.id));
            onClose();
        }
    } else if (props.start != null && props.end != null) {
        bgColor = "gray.700";
        hoverColor= "gray.600";
        textBtn = "Completado";
        icon = faCalendarCheck;
    } else if (btnDisabled(currentTime, props.scheduledStart, props.scheduledEnd, props.start, props.end)) {
        bgColor = "gray.700";
        hoverColor= "gray.600";
        textBtn = "Iniciar";
        icon = faCalendarTimes;
    } else {
        bgColor = "red.500";
        hoverColor= "red.400";
        textBtn = "Finalizar";
        icon = faCalendarTimes;
        handleClick = () =>{
            dispatch(checkOut(props.id));
            onClose();
        }
    }

    return <>
        <Button
            disabled={btnDisabled(currentTime, props.scheduledStart, props.scheduledEnd, props.start, props.end)}
            bg={bgColor}
            _hover={{bg: hoverColor}}
            isLoading={isFetching}
            color="white"
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100px"
            height="100px"
            onClick={onOpen}>
            <FontAwesomeIcon icon={icon} size="2x"/>
            <Text mt={2} fontSize="small">{textBtn}</Text>
        </Button>
        <Modal isCentered size="xs" onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Confirmar
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    ¿Está seguro que desea {textBtn.toLowerCase()} esta actividad?
                </ModalBody>

                <ModalFooter>
                    <Button bg='red.500' _hover={{bg: 'red.400'}} color='white' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button bg='cyan.650' _hover={{bg: 'cyan.600'}} color='white'
                            onClick={handleClick}>
                        Aceptar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

/**
 * Verifica si el boton de checkIn / checkOut debe estar desactivado
 * @param currentTime       Hora actual, en segundos
 * @param scheduledStart    Hora planificada de inicio, en segundos
 * @param scheduledEnd      Hora planificada de salida, en segundos
 * @param start             Hora de inicio real, en segundos
 * @param end               Hora de salida real, en segundos
 */
function btnDisabled(currentTime, scheduledStart, scheduledEnd, start, end): boolean {
    if (start != null && end == null) {
        return false
    }

    return (currentTime < scheduledStart || currentTime > scheduledEnd || end != null);
}
