import {PayloadAction} from "@reduxjs/toolkit";
import * as api from '../services/tareasServices';
import {call, put, takeLatest} from "redux-saga/effects";
import {
    finalizarTarea,
    finalizarTareaError,
    finalizarTareaSuccess,
    tareasUsuario,
    tareasUsuarioError,
    tareasUsuarioSuccess
} from "../slices/tareasSlice";

function* tareasUsuarioWorker(action: PayloadAction<void>) {
    try {
        const response = yield call(api.getTareasUsuario);
        yield put(tareasUsuarioSuccess(response.data));
    } catch (e) {
        yield put(tareasUsuarioError(`${e}`));
    }
}

function* finalizarTareaWorker(action: PayloadAction<number>) {
    try {
        const response = yield call(api.finalizarTarea, action.payload);
        yield put(finalizarTareaSuccess());
    } catch (e) {
        yield put(finalizarTareaError(`${e}`));
    }
}

export function* tareasSaga() {
    yield takeLatest(tareasUsuario, tareasUsuarioWorker);
    yield takeLatest(finalizarTarea, finalizarTareaWorker);
}
