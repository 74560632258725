import axios from "axios";
import {API_ENDPOINT} from "../config";
import Cookies from 'js-cookie';

export function apiClient(path = '') {
    let normalizedPath = path;
    if (path.startsWith('/')) {
        normalizedPath = normalizedPath.substr(1);
    }

    const token = Cookies.get('token');
    const authHeaders = token ? {Authorization: `Bearer ${token}`} : {};

    return axios.create({
        baseURL: `${API_ENDPOINT}/${normalizedPath}`,
        headers: {...authHeaders}
    });
}
