import {Flex, Text, VStack} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {getActividadesUsuarioSelector} from "../selectors/actividadesSelector";
import React, {useEffect} from "react";
import {actividadesUsuario} from "../slices/actividadesSlice";
import {ActividadCard} from "../component/ActividadCard"
import {RefreshDataBtn} from "../component/RefreshDataBtn";

export function Actividades() {
    const dispatch = useDispatch();
    const actividades = useSelector(getActividadesUsuarioSelector);

    useEffect(() => {
        dispatch(actividadesUsuario());
    }, []);

    const actividadesCards = actividades.map(actividad => {
        return <ActividadCard
            key={`${actividad.idActividad}`}
            id={actividad.idActividad}
            title={actividad.nombreActividad}
            pdv={actividad.nombrePdv}
            comment={actividad.comentario}
            scheduledStart={actividad.horaEntrada}
            scheduledEnd={actividad.horaSalida}
            start={actividad.horaIngreso}
            end={actividad.horaEgreso}
        />
    });

    return (
        <>
            <Flex justify="space-between" alignItems="center">
                <Text mt={5} fontSize="xl" textAlign="left" fontWeight="bold" mb={5}>
                    Actividades
                </Text>
                <RefreshDataBtn />
            </Flex>
            <VStack spacing="24px">
                {actividadesCards}
            </VStack>
        </>
    );
}
