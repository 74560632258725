import * as api from '../services/descansosServices';
import {call, put, takeLatest} from "redux-saga/effects";
import {
    descansosUsuario,
    descansosUsuarioSuccess,
    descansosUsuarioError,
    iniciarAlmuerzoSuccess,
    iniciarAlmuerzoError,
    iniciarAlmuerzo,
    finalizarDescanso,
    finalizarDescansoSuccess,
    finalizarAlmuerzoError,
    finalizarAlmuerzoSuccess,
    finalizarDescansoError,
    finalizarAlmuerzo, iniciarDescanso, iniciarDescansoSuccess, iniciarDescansoError
} from "../slices/descansosSlice";
import {PayloadAction} from "@reduxjs/toolkit";

function* descansosUsuarioWorker(action: PayloadAction<void>) {
    try {
        const response = yield call(api.getDescansosActivos);
        yield put(descansosUsuarioSuccess(response.data));
    } catch (e) {
        yield put(descansosUsuarioError(`${e}`));
    }
}

function* iniciarAlmuerzoWorker(action: PayloadAction<void>) {
    try {
        const response = yield call(api.postIniciarAlmuerzo);
        yield put(iniciarAlmuerzoSuccess(response.data));
    } catch (e) {
        yield put(iniciarAlmuerzoError(`${e}`));
    }
}

function* iniciarDescansoWorker(action: PayloadAction<string>) {
    try {
        const response = yield call(api.postIniciarDescanso, action.payload);
        yield put(iniciarDescansoSuccess(response.data));
    } catch (e) {
        yield put(iniciarDescansoError(`${e}`));
    }
}

function* finalizarAlmuerzoWorker(action: PayloadAction<number>) {
    try {
        const response = yield call(api.putFinalizarDescanso, action.payload);
        yield put(finalizarAlmuerzoSuccess(response.data));
    } catch (e) {
        yield put(finalizarAlmuerzoError(`${e}`));
    }
}

function* finalizarDescansoWorker(action: PayloadAction<number>) {
    try {
        const response = yield call(api.putFinalizarDescanso, action.payload);
        yield put(finalizarDescansoSuccess(response.data));
    } catch (e) {
        yield put(finalizarDescansoError(`${e}`));
    }
}

export function* descansosSaga() {
    yield takeLatest(descansosUsuario, descansosUsuarioWorker);
    yield takeLatest(iniciarAlmuerzo, iniciarAlmuerzoWorker);
    yield takeLatest(iniciarDescanso, iniciarDescansoWorker);
    yield takeLatest(finalizarAlmuerzo, finalizarAlmuerzoWorker);
    yield takeLatest(finalizarDescanso, finalizarDescansoWorker);
}
