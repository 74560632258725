import React from 'react';
import {Box, Button, ButtonProps, Text} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {TareaAsignacion} from '../../types/tareas';
import {Card} from '../Card';
import {Link} from 'react-router-dom';

export interface ExamTaskProps extends ButtonProps {
  task: TareaAsignacion;
}

export function ExamTask(props: ExamTaskProps) {
  const {task} = props;
  return (
    <>
      <Card py={5} px={0}>
        <Text px={5}>{'Realizar evaluación final del módulo'}</Text>
      </Card>
      <Button
        mt={5}
        w="100%"
        bg="blue.800"
        _hover={{bg: 'blue.700'}}
        color="white"
        size="lg"
        isDisabled={task.codEstadoTarea === 'terminado'}
        as={Link}
        to={`/tareas/${task.idTareaAsignacion}/evaluacion`}>
        <Box mr={3}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </Box>
        Realizar evaluación
      </Button>
    </>
  );
}
