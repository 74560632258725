import {Descanso, DescansosActivos} from "../types/descansos";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface DescansoState {
    isFetching: boolean,
    data: DescansosActivos | null,
    error: string | null
}

const initialState = {
    isFetching: false,
    data: null,
    error: null
} as DescansoState

const descansosSlice = createSlice({
    name: 'descansos',
    initialState,
    reducers: {
        descansosUsuario(state) {
            state.isFetching = true;
            state.data = null;
            state.error = null;
        },
        descansosUsuarioSuccess(state, action: PayloadAction<DescansosActivos>) {
            state.isFetching = false;
            state.data = action.payload;
            state.error = null;
        },
        descansosUsuarioError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.data = null;
            state.error = action.payload;
        },
        iniciarAlmuerzo(state) {
            state.isFetching = true;
            state.error = null;
        },
        iniciarAlmuerzoSuccess(state, action: PayloadAction<Descanso>) {
            state.isFetching = false;
            if (state.data != null) {
                state.data.almuerzo = action.payload;
            }
            state.error = null;
        },
        iniciarAlmuerzoError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = action.payload;
        },
        finalizarAlmuerzo(state, action: PayloadAction<number>) {
            state.isFetching = true;
            state.error = null;
        },
        finalizarAlmuerzoSuccess(state, action: PayloadAction<Descanso>) {
            state.isFetching = false;
            if (state.data != null) {
                state.data.almuerzo = action.payload;
            }
            state.error = null;
        },
        finalizarAlmuerzoError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = null;
        },
        iniciarDescanso(state, action: PayloadAction<string>) {
            state.isFetching = true;
            state.error = null;
        },
        iniciarDescansoSuccess(state, action: PayloadAction<Descanso>) {
            state.isFetching = false;
            if (state.data != null) {
                state.data.descanso = action.payload;
            }
            state.error = null;
        },
        iniciarDescansoError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = null;
        },
        finalizarDescanso(state, action: PayloadAction<number>) {
            state.isFetching = true;
            state.error = null;
        },
        finalizarDescansoSuccess(state, action: PayloadAction<Descanso>) {
            state.isFetching = false;
            if (state.data != null) {
                state.data.descanso = null;
            }
            state.error = null;
        },
        finalizarDescansoError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = null;
        }
    }
});

export const {
    descansosUsuario, descansosUsuarioSuccess, descansosUsuarioError,
    iniciarAlmuerzo, iniciarAlmuerzoSuccess, iniciarAlmuerzoError,
    iniciarDescanso, iniciarDescansoSuccess, iniciarDescansoError,
    finalizarAlmuerzo, finalizarAlmuerzoSuccess, finalizarAlmuerzoError,
    finalizarDescanso, finalizarDescansoSuccess, finalizarDescansoError
} = descansosSlice.actions;

export default descansosSlice.reducer;
