import React from 'react';
import {Card} from './Card';
import {Badge, Box, Button, Flex, Text} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom';

export type TaskCardProps = {
  title: string;
  module: string;
  startDate: string;
  endDate: string;
  status: string;
  isCompleted: boolean;
  btnLinkPath: string;
};

export function TaskCard(props: TaskCardProps) {
  let statusColor = 'gray';
  if (props.status === 'Activo') {
    statusColor = 'blue';
  } else if (props.status === 'Calificado') {
    statusColor = 'green';
  } else if (props.status === 'Entregado') {
    statusColor = 'yellow';
  } else if (props.status === 'Vencido') {
    statusColor = 'gray';
  }

  return (
    <Card>
      <Flex direction="row">
        <Box flex={1}>
          <Text fontWeight="bold" fontSize="2xl" mb={3}>
            {props.title}
          </Text>
          <Flex alignItems="center" mb={3}>
            <FontAwesomeIcon icon={faLayerGroup} />{' '}
            <Text ml={3}>{props.module}</Text>
          </Flex>
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faCalendarAlt} />{' '}
            <Text ml={3}>
              Del {props.startDate} al {props.endDate}
            </Text>
          </Flex>
        </Box>
        <Flex direction="column" justifyContent="space-between">
          <Box textAlign="right">
            <Badge
              variant="solid"
              colorScheme={statusColor}
              borderRadius={10}
              px={3}>
              {props.status}
            </Badge>
          </Box>
          <Button
            bg="blue.800"
            _hover={{bg: 'blue.700'}}
            color="white"
            size="sm"
            as={Link}
            to={props.btnLinkPath}>
            Ver tarea
            <Box ml={2}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Box>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
