import {RootState} from "../app/store";
import {TipoDescanso} from "../types/tiposDescanso";

function baseSelector(state: RootState) {
    return state.tiposDescanso;
}

export function allTiposDescansoSelector(state: RootState): TipoDescanso[] {
    return baseSelector(state).data;
}

export function getIsFetchingTiposDescansoSelector(state: RootState) {
    return baseSelector(state).isFetching;
}
