import {apiClient} from "./apiClient";
import {TareaAsignacion} from '../types/tareas';

const api = () => apiClient('/tareas-asignacion')

export function getTareasUsuario() {
    return api().get<TareaAsignacion[]>('');
}

export function finalizarTarea(idTareaAsignacion: number) {
    return api().put(`/${idTareaAsignacion}/finalizar`);
}
