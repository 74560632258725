import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TareaAsignacion} from '../types/tareas';

export interface TareasState {
    isFetching: boolean,
    data: TareaAsignacion[],
    error: string | null
}

const initialState = {
    isFetching: false,
    data: [],
    error: null
} as TareasState

const tareasSlice = createSlice({
    name: 'tareas',
    initialState,
    reducers: {
        tareasUsuario(state) {
            state.isFetching = true;
            state.data = [];
            state.error = null;
        },
        tareasUsuarioSuccess(state, action: PayloadAction<TareaAsignacion[]>) {
            state.isFetching = false;
            state.data = [...action.payload];
            state.error = null;
        },
        tareasUsuarioError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.data = [];
            state.error = action.payload;
        },
        finalizarTarea(state, action: PayloadAction<number>) {
            state.isFetching = true;
            state.error = null;
        },
        finalizarTareaSuccess(state) {
            state.isFetching = false;
            state.error = null;
            state.data = [];
        },
        finalizarTareaError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = action.payload;
        }
    }
});

export const {tareasUsuario, tareasUsuarioSuccess, tareasUsuarioError, finalizarTarea,
    finalizarTareaError, finalizarTareaSuccess} = tareasSlice.actions;

export default tareasSlice.reducer;
