import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface Curso {
    id: number,
    nombre: string
}

export interface CursoState {
    data: Curso[],
    error: string | null
}

const initialState = {
    data: [],
    error: null
} as CursoState;

const cursoSlice = createSlice({
    name: 'curso',
    initialState,
    reducers: {
        addCurso(state, action: PayloadAction<Curso>) {
            state.data.push(action.payload);
        }
    }
});

export const {addCurso} = cursoSlice.actions

export default cursoSlice.reducer
