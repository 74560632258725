import {useDispatch, useSelector} from 'react-redux';
import {Box, Text, VStack} from '@chakra-ui/react';
import {tareasUsuario} from '../slices/tareasSlice';
import {TaskCard} from '../component/TaskCard';
import {allTareasSelector} from '../selectors/tareasSelector';
import {useEffect} from 'react';

export function Tareas() {
  const dispatch = useDispatch();
  const tasks = useSelector(allTareasSelector);

  useEffect(() => {
    dispatch(tareasUsuario());
  }, []);

  const taskCards = tasks.map(task => {
    let status = task.nombreEstadoTarea;

    if (task.notaEntregable) {
      status = 'Calificado';
    } else if (task.codEstadoTarea === 'terminado') {
      status = 'Entregado';
    } else if (task.expired) {
      status = 'Vencido';
    }

    return (
      <TaskCard
        key={`${task.idTareaAsignacion}`}
        title={task.nombreTarea}
        module={task.nombreCurso}
        startDate={task.fechaInicio}
        endDate={task.fechaFin}
        status={status}
        isCompleted={task.codEstadoTarea === 'terminado'}
        btnLinkPath={`/tareas/${task.idTareaAsignacion}`}
      />
    );
  });

  return (
    <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
      <Text fontSize="4xl" textAlign="center" fontWeight="bold" mb={10}>
        Tareas
      </Text>
      <VStack spacing="24px">{taskCards}</VStack>
    </Box>
  );
}
