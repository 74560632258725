import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Cookies from 'js-cookie';

export interface AuthState {
    isFetching: boolean,
    isAuthenticated: boolean,
    username: string | null,
    error: string | null
}

export type UserData = {
    username: string,
    password: string
}

const initialState = {
    isFetching: false,
    username: null,
    isAuthenticated: !!Cookies.get('token'),
    error: null
} as AuthState

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: PayloadAction<UserData>) {
            state.isFetching = true;
            state.isAuthenticated = false;
            state.username = null;
            state.error = null;
        },
        loginSuccess(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.isAuthenticated = true;
            state.username = action.payload;
            state.error = null;
        },
        loginError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.isAuthenticated = false;
            state.username = null;
            state.error = action.payload;
        }
    }
});

export const {login, loginSuccess, loginError} = authSlice.actions;

export default authSlice.reducer;
