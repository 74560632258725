import {combineReducers} from "@reduxjs/toolkit";
import cursoSlice from './curso'
import authSlice from './authSlice';
import tareasSlice from "./tareasSlice";
import descansosSlice from "./descansosSlice";
import tiposDescansoSllice from "./tiposDescansoSllice";
import actividadesSlice from "./actividadesSlice";

const rootReducer = combineReducers({
    curso: cursoSlice,
    auth: authSlice,
    tareas: tareasSlice,
    descansos: descansosSlice,
    tiposDescanso: tiposDescansoSllice,
    actividades: actividadesSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
