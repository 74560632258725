import {RootState} from "../app/store";
import {ActividadCronograma} from "../types/actividades";

function baseSelector(state: RootState) {
    return state.actividades;
}

export function getActividadesUsuarioSelector(state: RootState): ActividadCronograma[] {
    return baseSelector(state).data;
}

export function isFetchingActividadesSelector(state: RootState) {
    return baseSelector(state).isFetching;
}
