import {RootState} from "../app/store";

export function baseSelector(state: RootState) {
    return state.auth;
}

export function getIsFetchingSelector(state: RootState) {
    return baseSelector(state).isFetching;
}

export function getErrorSelector(state: RootState) {
    return baseSelector(state).error;
}

export function getIsAuthenticatedSelector(state: RootState) {
    return baseSelector(state).isAuthenticated;
}

export function getUsername(state: RootState) {
    return baseSelector(state).username;
}
