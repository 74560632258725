import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faUpload} from '@fortawesome/free-solid-svg-icons';
import {useUppy} from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

export interface UploaderProps extends BoxProps {
  endpoint: string;
  textOnlyEndpoint: string;
  token: string;
  onComplete?: (e: boolean) => void;
  disabled?: boolean;
}

export interface UploaderHandle {
  upload: () => Promise<Boolean>;
}

export const Uploader = forwardRef<UploaderHandle, UploaderProps>(
  (props, ref) => {
    const {onComplete, disabled, textOnlyEndpoint, ...rest} = props;

    const toast = useToast();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [text, setText] = useState('');
    const [uploadInProgress, setUploadInProgress] = useState(false);

    /**
     * Este side effect se ejecuta cada vez que el texto o el archivo cambian
     * de lo contrario si el texto se ha modificado después de adjuntar el
     * archivo, no se actualizaría el texto en state de uppy
     */
    useEffect(() => {
      setUppyFile();
    }, [file, text]);

    const reset = () => {
      if (!inputRef.current) {
        return;
      }
      setFile(null);
      inputRef.current.type = 'text';
      inputRef.current.type = 'file';
    };

    const uppy = useUppy(() => {
      const instance = new Uppy({
        restrictions: {maxNumberOfFiles: 1},
        autoProceed: false,
      });

      instance.use(XHRUpload, {
        endpoint: props.endpoint,
        headers: {
          authorization: `Bearer ${props.token}`,
        },
      });

      instance.on('file-added', loadedFile => {
        setUploadInProgress(true);
      });
      return instance;
    });

    useImperativeHandle(ref, () => ({
      upload() {
        if (file != null) {
          return uppy.upload()
            .then((result) => {
              setUploadInProgress(false);
              if (result.failed.length > 0) {
                onComplete?.(false);
                toast({
                  title: 'Error',
                  description: 'Ocurrió un error al subir el archivo',
                  status: 'error',
                  duration: 5000,
                });
              } else {
                onComplete?.(true);
              }
              reset();
            }).catch((error) => {
              toast({
                title: 'Error',
                description: 'Ocurrió un error al publicar',
                status: 'error',
                duration: 5000,
              });
            })
            .then(x => true);
        }
        return uppy.upload()
          .then((result) => {
            setUploadInProgress(false);
            if (result.failed.length == 0 && result.successful.length == 0) {
              onComplete?.(false);
              toast({
                title: 'Advertencia',
                description: 'Debe adjuntar un archivo',
                status: 'warning',
                duration: 5000,
              });
            }
            reset();
          }).catch((error) => {
            toast({
              title: 'Error',
              description: 'Ocurrió un error al publicar',
              status: 'error',
              duration: 5000,
            });
          }).then(x => true);
      },
    }));

    const onFileChange = e => {
      if (!e.target.files) {
        return;
      }
      if (e.target.files.length === 0) {
        setFile(null);
        return;
      }

      const loadedFile = e.target.files.item(0);
      if (loadedFile) {
        setFile(loadedFile);
      }
    };

    /**
     * Agregar el archivo  la colección de archivos cargados de uppy
     */
    const setUppyFile = () => {
      if (!file) {
        return;
      }
      uppy.reset();
      uppy.addFile({
        name: file.name,
        type: file.type,
        data: file as File,
        meta: {
          descripcion: text,
        },
      });
    };

    const onAttachFileClick = () => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current?.click();
    };

    const onClearBtn = () => {
      reset();
    };

    return (
      <Box {...rest}>
        <input
          type="file"
          ref={inputRef}
          onChange={onFileChange}
          id="cm"
          hidden
        />
        <Textarea onChange={e => setText(e.target.value)} disabled={disabled} />
        <Flex direction="row" justify="end" align="center" mt={2}>
          <Text mr={2} fontSize="xs">
            {file?.name || ' '}
          </Text>
          <ButtonGroup size="xs" isAttached={!!file}>
            <Button
              disabled={disabled}
              colorScheme="teal"
              leftIcon={<FontAwesomeIcon icon={faUpload} />}
              onClick={onAttachFileClick}>
              Adjuntar archivo
            </Button>
            <IconButton
              disabled={disabled}
              onClick={onClearBtn}
              hidden={!file}
              colorScheme="yellow"
              aria-label="Eliminar"
              icon={<FontAwesomeIcon icon={faTimes} />}
            />
          </ButtonGroup>
        </Flex>
      </Box>
    );
  },
);
