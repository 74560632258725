import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import rootSaga from '../sagas';
import createSagaMiddleware from 'redux-saga'
import rootReducer from "../slices";

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({thunk: false}), sagaMiddleware];

const store = configureStore({
    middleware: middleware,
    reducer: rootReducer
});

sagaMiddleware.run(rootSaga);

// if (process.env.NODE_ENV === 'development' && module.hot) {
//     module.hot.accept('')
// }

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
