import React from 'react';
import logo from '../logo.svg';
import './App.css';
import {Routes} from "./Routes";
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import {Provider} from "react-redux";
import store from "./store";
import theme from '../theme/default';

function App() {
    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </ChakraProvider>
        </Provider>
    );
}

export default App;
