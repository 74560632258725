import {TipoDescanso} from "../types/tiposDescanso";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface TiposDescansoState {
    isFetching: boolean,
    data: TipoDescanso[],
    error: string | null
}

const initialState = {
    isFetching: false,
    data: [],
    error: null
} as TiposDescansoState

const tiposDescansoSlice = createSlice({
    name: 'tiposDescanso',
    initialState,
    reducers: {
        tiposDescanso(state) {
            state.isFetching = true;
            state.data = [];
            state.error = null;
        },
        tiposDescansoSuccess(state, action: PayloadAction<TipoDescanso[]>) {
            state.isFetching = false;
            state.data = [...action.payload];
            state.error = null;
        },
        tiposDescansoError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.data = [];
            state.error = action.payload;
        }
    }
});

export const {tiposDescanso, tiposDescansoSuccess, tiposDescansoError} = tiposDescansoSlice.actions;

export default tiposDescansoSlice.reducer;
