import React, {useEffect, useState} from 'react';
import {Evaluation} from '../component/Evaluation';
import {Box, Button, Center, Flex, Spinner, Text} from '@chakra-ui/react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../app/store';
import {getTareaByIdSelector} from '../selectors/tareasSelector';
import {Link, useParams} from 'react-router-dom';
import {tareasUsuario} from '../slices/tareasSlice';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function Evaluacion() {
  const params = useParams();
  const dispatch = useDispatch();
  const task = useSelector((state: RootState) =>
    getTareaByIdSelector(state, params['idTarea']),
  );

  const [taskChanges, setTaskChanges] = useState<number>(0);

  useEffect(() => {
    setTaskChanges(taskChanges + 1);
    if (!task) {
      dispatch(tareasUsuario());
    }
  }, [task]);

  if (!task) {
    return (
      <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
        <Center mt={10}>
          <Spinner />
        </Center>
      </Box>
    );
  }

  console.log({taskChanges});
  if (task.codEstadoTarea == 'terminado' && taskChanges < 3) {
    return (
      <Flex
        px={5}
        py={5}
        bg="gray.45"
        h="100vh"
        overflow="auto"
        direction="column"
        justifyContent="center"
        alignItems="center">
        <Flex justifyContent="center" fontSize="150px" color="teal.250">
          <FontAwesomeIcon icon={faCheckCircle} />
        </Flex>
        <Text fontSize={24} textAlign="center">
          Evaluación realizada
        </Text>
        <Button
          mt={5}
          w="100%"
          bg="blue.800"
          _hover={{bg: 'blue.700'}}
          color="white"
          size="lg"
          as={Link}
          to={`/tareas`}>
          Volver
        </Button>
      </Flex>
    );
  }

  return (
    <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
      <Evaluation
        shouldComplete={task.codEstadoTarea !== 'terminado'}
        idEvaluacion={task.evaluacionTypeformId}
        idAsignacion={task.idTareaAsignacion}
      />
    </Box>
  );
}
