import {apiClient} from "./apiClient";
import {ActividadCronograma} from "../types/actividades";

const api = () => apiClient('/cronograma/actividades');

export function getActividadesUsuario() {
    return api().get<ActividadCronograma[]>('');
}

export function putCheckIn(idActividad) {
    return api().put<ActividadCronograma>(`/${idActividad}/check-in`);
}

export function putCheckOut(idActividad) {
    return api().put<ActividadCronograma>(`/${idActividad}/check-out`);
}
