import {Box, Button, Flex, HStack, Text} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import {ReactNode, useEffect} from "react";
import {descansosUsuario} from "../slices/descansosSlice";
import {allDescansosSelector} from "../selectors/descansosSelector";
import {ConfirmAlmuerzoModal} from "../component/ConfirmAlmuerzoModal";
import {ConfirmarDescansoModal} from "../component/ConfirmarDescansoModal";
import {Actividades} from "./Actividades";


export function Home() {
    const dispatch = useDispatch();
    const descansos = useSelector(allDescansosSelector);

    useEffect(() => {
        dispatch(descansosUsuario());
    }, []);

    let btnAlmuerzo: ReactNode;
    let btnBreak: ReactNode;

    let propsBtnAlmuerzo;
    let propsBtnDescanso;

    if (descansos != null && descansos.almuerzo != null) {
        propsBtnAlmuerzo = {
            idDescanso: descansos.almuerzo.idDescanso,
            fecha: descansos.almuerzo.fecha,
            horaInicio: descansos.almuerzo.horaInicio,
            horaFin: descansos.almuerzo.horaFin,
            tipo: descansos.almuerzo.tipo
        };
    } else {
        propsBtnAlmuerzo = {
            idDescanso: null,
            fecha: null,
            horaInicio: null,
            horaFin: null,
            tipo: null
        };
    }

    if (descansos != null && descansos.descanso != null) {
        propsBtnDescanso = {
            idDescanso: descansos.descanso.idDescanso,
            fecha: descansos.descanso.fecha,
            horaInicio: descansos.descanso.horaInicio,
            horaFin: descansos.descanso.horaFin,
            tipo: descansos.descanso.tipo
        }
    } else {
        propsBtnDescanso = {
            idDescanso: null,
            fecha: null,
            horaInicio: null,
            horaFin: null,
            tipo: null
        }
    }

    btnAlmuerzo = (
        <ConfirmAlmuerzoModal {...propsBtnAlmuerzo} />
    );

    btnBreak = (
        <ConfirmarDescansoModal {...propsBtnDescanso} />
    );

    return (
        <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
            <Text fontSize="4xl" textAlign="center" fontWeight="bold" mb={5}>Bienvenido</Text>

            <Button mt={5} w="100%" bg="green.500" _hover={{bg: 'green.400'}} color="white" as={Link} to="/tareas">
                Ver Tareas
            </Button>

            <Flex mt={5} direction="row" justifyContent="center">
                <HStack spacing={10}>
                    {btnAlmuerzo}
                    {btnBreak}
                </HStack>
            </Flex>
            <Actividades />
        </Box>
    );
}
