import {RootState} from "../app/store";
import {DescansosActivos} from "../types/descansos";

function baseSelector(state: RootState) {
    return state.descansos;
}

export function allDescansosSelector(state: RootState): DescansosActivos | null {
    return baseSelector(state).data;
}

export function getIsFetchingSelector(state: RootState) {
    return baseSelector(state).isFetching;
}
