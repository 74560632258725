import {ActividadCardProps} from "../types/actividades";
import {Card} from "./Card";
import {Box, Flex, Text} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faClock, faLocationArrow, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {format, fromUnixTime} from "date-fns";
import {ConfirmCheckInOutModal} from "./ConfirmCheckInOutModal";

/**
 * Card utilizada para las actividades
 * @param props Propiedades utilizadas en el componente
 * @constructor
 */
export function ActividadCard(props: ActividadCardProps) {

    const commentItem = <>
        <FontAwesomeIcon icon={faPencilAlt} />
        <Text ml={3} fontSize="small">
            {props.comment}
        </Text>
    </>;

    return <Card>
        <Box flex={1}>
            <Text fontWeight="bold" fontSize="2xl" mb={3}>{props.title}</Text>
        </Box>
        <Flex direction="row">
            <Box flex={1}>
                <Flex alignItems="center" mb={2}>
                    <FontAwesomeIcon icon={faLocationArrow} />
                    <Text ml={3}>{props.pdv}</Text>
                </Flex>
                <Flex alignItems="center" mb={2}>
                    <FontAwesomeIcon icon={faClock} />
                    <Text ml={3} fontSize="small">
                        {formatTime(props.scheduledStart)} - {formatTime(props.scheduledEnd)}
                    </Text>
                </Flex>
                <Flex alignItems="center">
                    {props.comment && commentItem}
                </Flex>
            </Box>
            <Flex direction="row" justifyContent="space-between" ml={1} alignItems="center">
                <ConfirmCheckInOutModal {...props} />
            </Flex>
        </Flex>
    </Card>
}

function formatTime(timestamp: number) {
    return format(fromUnixTime(timestamp), 'hh:mm aa')
}
