import { takeLatest, call, put } from "redux-saga/effects";
import {login, loginError, loginSuccess, UserData} from "../slices/authSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import * as api from '../services/authServices';
import Cookies from 'js-cookie';

function* loginWorker(action: PayloadAction<UserData>) {
    try {
        const result = yield call(api.login, action.payload.username, action.payload.password);

        const expirationDate = new Date(result.data.expiration);
        expirationDate.setMinutes(expirationDate.getMinutes() - 2); // Se restan 2 minutos

        Cookies.set('token', result.data.token, {
            expires: expirationDate
        });

        yield put(loginSuccess(result.data.username));
    } catch (e) {
        Cookies.remove('token');
        yield put(loginError(`${e}`));
    }
}

export function* authSaga() {
    yield takeLatest(login, loginWorker);
}
