import {apiClient} from "./apiClient";

const api = () => apiClient('/auth');

export type loginResponse = {
    token: string,
    expiration: Date,
    username: string
}

export function login(username, password) {
    return api().post<loginResponse>('/login', {
        username: username,
        password: password
    });
}
