import {apiClient} from "./apiClient";
import {Descanso, DescansosActivos} from "../types/descansos";

const api = () => apiClient('/cronograma/descansos')

export function getDescansosActivos() {
    return api().get<DescansosActivos>('/activos');
}

export function postIniciarAlmuerzo() {
    return api().post<Descanso>('/iniciar-almuerzo');
}

export function postIniciarDescanso(codTipoBreak) {
    return api().post<Descanso>('/iniciar-break', {
        codTipoDescanso: codTipoBreak
    });
}

export function putFinalizarDescanso(idDescanso: number) {
    return api().put<Descanso>(`${idDescanso}/finalizar`);
}
