import {Widget} from '@typeform/embed-react';
import React from 'react';
import {Evaluaciones} from '../types/evaluaciones';
import {v4 as uuidv4} from 'uuid';
import {finalizarTarea} from '../slices/tareasSlice';
import {useDispatch} from 'react-redux';

export interface EvProps extends Evaluaciones {
  shouldComplete: boolean;
}

export function Evaluation(props: EvProps) {
  const {idEvaluacion, idAsignacion} = props;
  const dispatch = useDispatch();
  const responseUuid = uuidv4();

  const onBtnClick = () => {
    setTimeout(() => {
      dispatch(finalizarTarea(idAsignacion));
    }, 5000);
  };

  return (
    <>
      <Widget
        id={idEvaluacion}
        style={{width: '100%', height: '100%'}}
        hidden={{
          response_uuid: responseUuid,
          asignacion_id: idAsignacion.toString(),
        }}
        onSubmit={onBtnClick}
        onReady={() => {
          if (props.shouldComplete) {
            dispatch(finalizarTarea(idAsignacion));
          }
        }}
      />
    </>
  );
}
