import {AspectRatio, Text} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import {TareaAsignacion} from '../../types/tareas';
import {Card} from '../Card';
import {PrimaryButton} from '../buttons/PrimaryButton';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router';
import {finalizarTarea} from '../../slices/tareasSlice';
import {useDispatch} from 'react-redux';

export interface VideoTaskProps {
  task: TareaAsignacion;
}

export function VideoTask(props: VideoTaskProps) {
  const {task} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [hasBeenWatched, setHasBeenWatched] = useState(false);
  const videoRef = useRef<null | HTMLVideoElement>(null);

  let btnName;
  if (task.tieneEvaluacion) {
    btnName = 'Realizar evaluación';
  } else {
    btnName = 'Marcar como completado';
  }

  const handleProgress = () => {
    if (!videoRef.current) {
      return;
    }
    const has99Percent =
      videoRef.current.currentTime >= videoRef.current.duration * 0.99;
    setHasBeenWatched(has99Percent);
  };

  const onBtnClick = () => {
    if (task.tieneEvaluacion) {
      history.push(`/tareas/${task.idTareaAsignacion}/evaluacion`);
    } else {
      dispatch(finalizarTarea(Number(task.idTareaAsignacion)));
    }
  };

  return (
    <>
      <Card noPadding>
        <Text p={6}>{task.descripcionTarea || '--- Sin descripción ---'}</Text>
        <AspectRatio w="100%" bg="black" ratio={4 / 3}>
          <video
            ref={videoRef}
            onTimeUpdate={handleProgress}
            title={task.nombreUpload}
            controls>
            <source src={task.uploadFullPath} type={task.tipoUpload} />
          </video>
        </AspectRatio>
      </Card>
      <PrimaryButton
        mt={5}
        isDisabled={!hasBeenWatched || task.codEstadoTarea === 'terminado'}
        leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
        onClick={onBtnClick}>
        {btnName}
      </PrimaryButton>
    </>
  );
}
