import {RootState} from "../app/store";
import {TareaAsignacion} from "../types/tareas";

function baseSelector(state: RootState) {
    return state.tareas;
}

export function allTareasSelector(state: RootState): TareaAsignacion[] {
    return baseSelector(state).data;
}

export function getTareaByIdSelector(state: RootState, id: number): TareaAsignacion | null {
    const result = allTareasSelector(state).find(task => task.idTareaAsignacion === Number(id));
    if (!result) {
        return null;
    }
    return result;
}

export function getIsFetchingSelector(state: RootState) {
    return baseSelector(state).isFetching;
}
