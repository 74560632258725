import React, {useRef, useState} from 'react';
import {TareaAsignacion} from '../../types/tareas';
import {Card} from '../Card';
import {
  Button,
  Center,
  Divider,
  Flex,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import {PrimaryButton} from '../buttons/PrimaryButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import {finalizarTarea} from '../../slices/tareasSlice';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import {API_ENDPOINT} from '../../config';
import Cookies from 'js-cookie';
import {Uploader, UploaderHandle} from '../Uploader';

export interface AttachmentTaskProps {
  task: TareaAsignacion;
}

export function AttachmentTask(props: AttachmentTaskProps) {
  const {task} = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  const uploaderRef = useRef<UploaderHandle>(null);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const onBtnClick = () => {
    if (task.tieneEvaluacion) {
      history.push(`/tareas/${task.idTareaAsignacion}/evaluacion`);
      return;
    }
    if (task.tieneEntregable && uploaderRef.current) {
      if (!uploaderRef.current) {
        return;
      }
      setIsInProgress(true);
      uploaderRef.current.upload();
    }
  };

  let btnName;
  if (task.tieneEvaluacion) {
    btnName = 'Realizar evaluación';
  } else if (task.tieneEntregable) {
    btnName = 'Cargar entregable';
  } else {
    btnName = 'Marcar como completado';
  }

  const sendTask = (status) => {
    setIsInProgress(false);
    if (!status) {
      return;
    }
    dispatch(finalizarTarea(Number(task.idTareaAsignacion)));
  }

  let uploader;
  if (task.tieneEntregable) {
    uploader = (
      <>
        <Divider/>
        <Uploader
          ref={uploaderRef}
          m={2}
          disabled={!hasDownloaded || task.codEstadoTarea === 'terminado'}
          token={Cookies.get('token')}
          textOnlyEndpoint={`/tareas-asignacion/${task.idTareaAsignacion}/entregable`}
          endpoint={`${API_ENDPOINT}/tareas-asignacion/${task.idTareaAsignacion}/entregable-upload`}
          onComplete={(status: boolean) => sendTask(status)}
        />
      </>
    );
  }

  return (
    <>
      <Card py={5} px={0}>
        <Text px={5}>{task.descripcionTarea || '...'}</Text>
        <Divider />
        <Center>
          <Button
            margin="auto"
            p={5}
            my={5}
            h="auto"
            border={2}
            borderColor="gray.500"
            borderStyle="dashed"
            w={150}
            borderRadius={10}
            as={Link}
            href={task.uploadFullPath}
            isExternal
            onClick={() => setHasDownloaded(true)}>
            <Flex direction="column" justify="center" align="center">
              <FontAwesomeIcon icon={faFileDownload} size="4x" />
              <Text fontSize="xs" mt={2}>
                Descargar
              </Text>
            </Flex>
          </Button>
        </Center>
        {uploader}
      </Card>
      <PrimaryButton
        mt={5}
        isLoading={isInProgress}
        isDisabled={!hasDownloaded || task.codEstadoTarea === 'terminado'}
        leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
        onClick={onBtnClick}>
        {btnName}
      </PrimaryButton>
    </>
  );
}
