import {PayloadAction} from "@reduxjs/toolkit";
import {call, put, takeLatest} from "redux-saga/effects";
import * as api from '../services/actividadesService';
import {
    actividadesUsuario,
    actividadesUsuarioError,
    actividadesUsuarioSuccess, checkIn,
    checkInError,
    checkInSuccess, checkOut, checkOutError, checkOutSuccess
} from "../slices/actividadesSlice";

function* actividadesUsuarioWorker(action: PayloadAction<void>) {
    try {
        const response = yield call(api.getActividadesUsuario);
        yield put(actividadesUsuarioSuccess(response.data));
    } catch (e) {
        yield put(actividadesUsuarioError(`${e}`));
    }
}

function* checkInWorker(action: PayloadAction<number>) {
    try {
        const response = yield call(api.putCheckIn, action.payload);
        yield put(checkInSuccess(response.data));
    } catch (e) {
        yield put(checkInError(`${e}`));
    }
}

function* checkOutWorker(action: PayloadAction<number>) {
    try {
        const response = yield call(api.putCheckOut, action.payload);
        yield put(checkOutSuccess(response.data));
    } catch (e) {
        yield put(checkOutError(`${e}`));
    }
}

export function* actividadesSaga() {
    yield takeLatest(actividadesUsuario, actividadesUsuarioWorker);
    yield takeLatest(checkIn, checkInWorker);
    yield takeLatest(checkOut, checkOutWorker);
}
