import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {finalizarTarea, tareasUsuario} from '../slices/tareasSlice';
import {
  getIsFetchingSelector,
  getTareaByIdSelector,
} from '../selectors/tareasSelector';
import {RootState} from '../app/store';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {Card} from '../component/Card';
import {
  faCheck,
  faCheckCircle,
  faComment,
  faHashtag,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {VideoTask} from '../component/tasks/VideoTask';
import {AttachmentTask} from '../component/tasks/AttachmentTask';
import {ExamTask} from '../component/tasks/ExamTask';
import {TaskBtn} from '../component/tasks/TaskBtn';
import {
  faCalendarTimes,
  faClipboard,
} from '@fortawesome/free-regular-svg-icons';

export function TareaDetalle() {
  const params = useParams();
  const dispatch = useDispatch();
  const ref = useRef<any | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const isFetching = useSelector(getIsFetchingSelector);

  const task = useSelector((state: RootState) =>
    getTareaByIdSelector(state, params['idTarea']),
  );

  useEffect(() => {
    if (!task) {
      dispatch(tareasUsuario());
    }
  }, [task]);

  const onBtnClick = () => {
    dispatch(finalizarTarea(Number(params['idTarea'])));
  };

  if (!task) {
    return (
      <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
        <Center mt={10}>
          <Spinner />
        </Center>
      </Box>
    );
  }

  if (
    task.codEstadoTarea === 'terminado' &&
    task.esEvaluacion &&
    task.notaEntregable
  ) {
    if (task.notaEntregable >= 80) {
      return (
        <Flex
          height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column">
          <Box color="green">
            <FontAwesomeIcon icon={faCheckCircle} size="10x" />
          </Box>
          <Heading>Aprobado</Heading>
        </Flex>
      );
    } else {
      return (
        <Flex
          height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column">
          <Box color="tomato">
            <FontAwesomeIcon icon={faTimesCircle} size="10x" />
          </Box>
          <Heading>Reprobado</Heading>
        </Flex>
      );
    }
  }

  if (task.codEstadoTarea === 'terminado') {
    let comment;
    let score;

    if (task.notaEntregable) {
      score = (
        <Flex alignItems="center" w="100%" mb={2}>
          <FontAwesomeIcon icon={faHashtag} />
          <Text mx={1}>Calificación</Text>
          <Box
            fontSize={12}
            bg="blue.700"
            px={5}
            py={1}
            borderRadius={25}
            color="white"
            fontWeight="bold">
            {task.notaEntregable}
          </Box>
        </Flex>
      );
    }

    if (task.comentarioEntregable) {
      comment = (
        <Box w="100%">
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faComment} />
            <Text mx={1}>Comentario</Text>
          </Flex>
          <Box
            minHeight={50}
            minW={200}
            fontSize={12}
            bg="gray.200"
            px={5}
            py={1}
            borderRadius={10}>
            {task.comentarioEntregable}
          </Box>
        </Box>
      );
    }

    return (
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
        direction="column">
        <Box position="relative" color="blue.800">
          <FontAwesomeIcon icon={faClipboard} size="10x" />
          <Box
            color="green.500"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -20%)">
            <FontAwesomeIcon icon={faCheck} size="4x" />
          </Box>
        </Box>
        <Heading mb={score || comment ? 10 : 0}>Tarea completada</Heading>
        <Box>
          {score}
          {comment}
        </Box>
      </Flex>
    );
  }

  if (task.expired) {
    return (
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
        direction="column">
        <Box color="blue.800">
          <FontAwesomeIcon icon={faCalendarTimes} size="10x" />
        </Box>
        <Heading mt={3}>Tarea expirada</Heading>
      </Flex>
    );
  }

  let completeBtn: ReactNode | null = null;
  let evaluationBtn: ReactNode | null = null;

  if (task.evaluacionTypeformId && task.codEstadoTarea !== 'terminado') {
    evaluationBtn = (
      <form action={`/tareas/${params['idTarea']}/evaluacion`}>
        <Button
          mt={5}
          w="100%"
          bg="blue.800"
          _hover={{bg: 'blue.700'}}
          color="white"
          size="lg"
          isDisabled={isDisabled}
          type="submit"
          isLoading={isFetching}>
          <Box mr={3}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </Box>
          Realizar evaluación
        </Button>
      </form>
    );
  } else {
    if (task.codEstadoTarea !== 'terminado') {
      completeBtn = (
        <Button
          mt={5}
          w="100%"
          bg="blue.800"
          _hover={{bg: 'blue.700'}}
          color="white"
          size="lg"
          isDisabled={isDisabled}
          onClick={onBtnClick}
          isLoading={isFetching}>
          <Box mr={3}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </Box>
          Marcar como completado
        </Button>
      );
    }
  }

  function handleProgress() {
    if (ref.current.currentTime >= ref.current.duration * 0.99) {
      setIsDisabled(false);
    }
  }

  let taskComponent;
  if (task.tieneVideo) {
    taskComponent = <VideoTask task={task} />;
  } else if (task.tieneAdjunto) {
    taskComponent = <AttachmentTask task={task} />;
  } else if (task.esEvaluacion) {
    taskComponent = <ExamTask task={task} />;
  } else if (task.tieneEntregable) {
    taskComponent = <TaskBtn task={task} />;
  } else {
    taskComponent = (
      <>
        <Card noPadding>
          <Text p={6}>
            {task.descripcionTarea || '--- Sin descripción ---'}
          </Text>
          <AspectRatio w="100%" bg="black">
            <video
              onTimeUpdate={handleProgress}
              ref={ref}
              title={task.nombreUpload}
              src={task.uploadFullPath}
              controls
            />
          </AspectRatio>
        </Card>
        {evaluationBtn}
        {completeBtn}
        <br />
      </>
    );
  }

  return (
    <Box px={5} py={5} bg="gray.45" h="100vh" overflow="auto">
      <Text fontSize="4xl" textAlign="center" fontWeight="bold" mb={10}>
        {task.nombreTarea}
      </Text>
      {taskComponent}
    </Box>
  );
}
