import React, {ReactNode} from 'react';
import {Box, BoxProps} from '@chakra-ui/react';

export interface CardProps extends BoxProps {
  children: ReactNode;
  noPadding?: boolean;
}

export function Card(props: CardProps) {
  const {noPadding, ...rest} = props;
  return (
    <Box
      boxShadow="lg"
      p={noPadding ? 0 : 6}
      rounded="md"
      bg="white"
      w="100%"
      {...rest}>
      {props.children}
    </Box>
  );
}
