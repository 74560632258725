import {ActividadCronograma} from "../types/actividades";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ActividadesState {
    isFetching: boolean,
    data: ActividadCronograma[],
    error: string | null
}

const initialState = {
    isFetching: false,
    data: [],
    error: null
} as ActividadesState

const updateActividadInArray = (state: ActividadesState, actividad: ActividadCronograma) => {
    const index = state.data.findIndex((item) => item.idActividad === actividad.idActividad);
    return state.data[index] = actividad;
}

const actividadesSlice = createSlice({
    name: 'actividades',
    initialState,
    reducers: {
        actividadesUsuario(state) {
            state.isFetching = true;
            state.data = [];
            state.error = null;
        },
        actividadesUsuarioSuccess(state, action: PayloadAction<ActividadCronograma[]>) {
            state.isFetching = false;
            state.data = [...action.payload];
            state.error = null;
        },
        actividadesUsuarioError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.data = [];
            state.error = action.payload;
        },
        checkIn(state, action: PayloadAction<number>) {
            state.isFetching = true;
            state.error = null;
        },
        checkInSuccess(state, action: PayloadAction<ActividadCronograma>) {
            state.isFetching = false;
            updateActividadInArray(state, action.payload);
            state.error = null;
        },
        checkInError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = action.payload;
        },
        checkOut(state, action: PayloadAction<number>) {
            state.isFetching = true;
            state.error = null;
        },
        checkOutSuccess(state, action: PayloadAction<ActividadCronograma>) {
            state.isFetching = false;
            updateActividadInArray(state, action.payload);
            state.error = null;
        },
        checkOutError(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.error = action.payload;
        }
    }
});

export const {
    actividadesUsuario, actividadesUsuarioSuccess, actividadesUsuarioError,
    checkIn, checkInSuccess, checkInError,
    checkOut, checkOutSuccess, checkOutError
} = actividadesSlice.actions;

export default actividadesSlice.reducer;
