import {PayloadAction} from "@reduxjs/toolkit";
import * as api from '../services/tiposDescansoService';
import {call, put, takeLatest} from "redux-saga/effects";
import {tiposDescanso, tiposDescansoError, tiposDescansoSuccess} from "../slices/tiposDescansoSllice";

function* tiposDescansoWorker(action: PayloadAction<void>) {
    try {
        const response = yield call(api.getTiposDescanso);
        yield put(tiposDescansoSuccess(response.data));
    } catch (e) {
        yield put(tiposDescansoError(`${e}`));
    }
}

export function* tiposDescansoSaga() {
    yield takeLatest(tiposDescanso, tiposDescansoWorker);
}
