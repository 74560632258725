import React, {useRef} from 'react';
import {Box, Button, Divider, Text} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {Card} from '../Card';
import {Uploader, UploaderHandle} from '../Uploader';
import Cookies from 'js-cookie';
import {API_ENDPOINT} from '../../config';
import {finalizarTarea} from '../../slices/tareasSlice';
import {useDispatch} from 'react-redux';

export function TaskBtn(props: any) {
  const {task} = props;
  const uploaderRef = useRef<UploaderHandle>(null);
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = React.useState(false);

  const onCompleteBtnClick = () => {
    if (!uploaderRef.current) {
      return;
    }
    setInProgress(true);
    uploaderRef.current.upload();
  };

  const sendTask = (status) => {
    setInProgress(false);
    if (!status) {
      return;
    }
    dispatch(finalizarTarea(Number(task.idTareaAsignacion)));
  }

  return (
    <>
      <Card py={5} px={0}>
        <Text px={5}>{task.descripcionTarea}</Text>
        <Divider/>
        <Uploader
          ref={uploaderRef}
          m={2}
          disabled={task.codEstadoTarea === 'terminado'}
          token={Cookies.get('token')}
          textOnlyEndpoint={`/tareas-asignacion/${task.idTareaAsignacion}/entregable`}
          endpoint={`${API_ENDPOINT}/tareas-asignacion/${task.idTareaAsignacion}/entregable-upload`}
          onComplete={(status: boolean) => sendTask(status)}
        />
      </Card>

      <Button
        mt={5}
        w="100%"
        bg="blue.800"
        _hover={{bg: 'blue.700'}}
        color="white"
        size="lg"
        isDisabled={task.codEstadoTarea === 'terminado'}
        isLoading={inProgress}
        onClick={onCompleteBtnClick}>
        <Box mr={3}>
          <FontAwesomeIcon icon={faCheckCircle}/>
        </Box>
        Completar tarea
      </Button>
    </>
  );
}
