import {all} from 'redux-saga/effects';
import {authSaga} from "./authSaga";
import {tareasSaga} from "./tareasSaga";
import {descansosSaga} from "./descansosSagas";
import {tiposDescansoSaga} from "./tiposDescansoSagas";
import {actividadesSaga} from "./actividadesSaga";

export default function* rootSaga() {
    yield all([
        authSaga(),
        tareasSaga(),
        descansosSaga(),
        tiposDescansoSaga(),
        actividadesSaga()
    ]);
}
