import {DescansosButtonsProps} from "../types/descansos";
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {getIsFetchingSelector} from "../selectors/descansosSelector";
import React, {ReactNode, useEffect} from "react";
import {finalizarDescanso, iniciarDescanso} from "../slices/descansosSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassHalf, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {allTiposDescansoSelector} from "../selectors/tiposDescansoSelector";
import {tiposDescanso} from "../slices/tiposDescansoSllice";
import {formatTimestampToTime} from "../helpers/DateTimeUtils";

/**
 * Modal utilizado para confirmar el inicio / fin de un descanso. Tambien incluye el boton que abre el modal
 * @param props Propiedades utilizadas en el componente
 * @constructor
 */
export function ConfirmarDescansoModal(props: DescansosButtonsProps) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const dispatch = useDispatch();
    const isFetching = useSelector(getIsFetchingSelector);

    const tiposDescansoList = useSelector(allTiposDescansoSelector);

    useEffect(() => {
        dispatch(tiposDescanso());
    }, []);

    let textModal: string;

    let selectTipoDescanso: ReactNode = null;
    let selectedOptionVal: string = "";

    let stackDescanso: ReactNode;
    let bgDescanso: string;
    let hoverColor: string;

    function onClickIniciarDescanso() {
        if (!selectedOptionVal || selectedOptionVal === '') {
            return;
        }
        dispatch(iniciarDescanso(selectedOptionVal));
        onClose();
    }

    function onClickFinalizarDescanso() {
        dispatch(finalizarDescanso(props.idDescanso));
        onClose();
    }

    if (canIniciarDescanso(props.idDescanso, props.horaFin)) {
        bgDescanso = 'aqua.500';
        hoverColor = 'aqua.400';
        textModal = "iniciar un nuevo descanso";
        stackDescanso = <>
            <FontAwesomeIcon icon={faHourglassHalf} size="2x" />
            <Text mt={2} fontSize="small">Iniciar descanso</Text>
        </>

        const handleOnChange = e => {
            selectedOptionVal = e.target.value;
        }

        let optionsTipoDescanso = tiposDescansoList.map(tipo => {
            return <option key={tipo.idTipoDescanso} value={tipo.codTipo}>{tipo.nombre}</option>
        });

        selectTipoDescanso = (
            <FormControl mt={3}>
                <FormLabel>Tipo de descanso</FormLabel>
                <Select placeholder="Seleccione el tipo de descanso" defaultValue="" onChange={handleOnChange}>
                    ${optionsTipoDescanso}
                </Select>
            </FormControl>
        );
    } else {
        bgDescanso = 'red.500';
        hoverColor = 'red.400';
        textModal = "finalizar el descanso actual";
        stackDescanso = <>
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            <Text mt={2} fontSize="x-small">Finalizar descanso</Text>
            <Text mt={1} fontSize="xx-small">{props.tipo}</Text>
            <Text mt={1} fontSize="xx-small">Inicio: {formatTimestampToTime(props.horaInicio)}</Text>
        </>
    }

    return (
        <>
            <Button
                bg={bgDescanso}
                _hover={{bg: hoverColor}}
                color='white'
                isLoading={isFetching}
                width="125px"
                height="125px"
                display="flex"
                alignItems="center"
                flexDirection="column"
                whiteSpace="normal"
                wordBreak="break-word"
                onClick={onOpen}>
                {stackDescanso}
            </Button>
            <Modal isCentered size="xs" onClose={onClose} isOpen={isOpen}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>
                        Confirmar
                    </ModalHeader>
                    <ModalCloseButton/>

                    <ModalBody>
                        ¿Está seguro que desea {textModal}?
                        {selectTipoDescanso}
                    </ModalBody>

                    <ModalFooter>
                        <Button bg='red.500' _hover={{bg: 'red.400'}} mr={3} onClick={onClose} color="white">
                            Cancelar
                        </Button>
                        <Button bg='cyan.650' _hover={{bg: 'cyan.600'}} color="white"
                                onClick={canIniciarDescanso(props.idDescanso, props.horaFin) ?
                                    onClickIniciarDescanso : onClickFinalizarDescanso}>
                            Aceptar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

/**
 * Verifica si el usuario puede iniciar un descanso
 * @param idDescanso    Id del descanso
 * @param horaFin       Timestamp en segundos de la fecha de finalizacion del descanso (puede ser null)
 */
function canIniciarDescanso(idDescanso: number, horaFin: number) {
    if (idDescanso == null) {
        return true;
    }

    if (horaFin != null) {
        return false;
    }

    return false;
}

