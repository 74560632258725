import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {getIsFetchingSelector} from "../selectors/descansosSelector";
import React, {ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faUtensils} from "@fortawesome/free-solid-svg-icons";
import {finalizarAlmuerzo, iniciarAlmuerzo} from "../slices/descansosSlice";
import {DescansosButtonsProps} from "../types/descansos";
import {formatTimestampToTime} from "../helpers/DateTimeUtils";

/**
 * Modal utilizado para confirmar el inicio / fin de almuerzo. Tambien incluye el boton que abre el modal
 * @param props Propiedades utilizadas en el componente
 * @constructor
 */
export function ConfirmAlmuerzoModal(props: DescansosButtonsProps) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const dispatch = useDispatch();
    const isFetching = useSelector(getIsFetchingSelector);

    let textModal: string = "";

    let stackAlmuerzo: ReactNode;
    let bgAlmuerzo: string;
    let hoverColor: string;

    if (canIniciarDescanso(props.idDescanso, props.horaFin)) {
        bgAlmuerzo = '#E09F3E';
        hoverColor = '#f0aa43';
        textModal = "iniciar"
        stackAlmuerzo = <>
            <FontAwesomeIcon icon={faUtensils} size="2x" />
            <Text mt={2} fontSize="small">Iniciar almuerzo</Text>
        </>
    } else if (props.idDescanso != null && props.horaFin != null) {
        bgAlmuerzo = 'gray.700';
        hoverColor= "gray.600";
        stackAlmuerzo = <>
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            <Text mt={2} fontSize="x-small" >Almuerzo finalizado</Text>
            <Text mt={1} fontSize="xx-small">Fin: {formatTimestampToTime(props.horaFin)}</Text>
        </>
    } else {
        bgAlmuerzo = 'red.500';
        hoverColor = 'red.400';
        textModal = "finalizar"
        stackAlmuerzo = <>
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            <Text mt={2} fontSize="x-small">Finalizar almuerzo</Text>
            <Text mt={1} fontSize="xx-small">Inicio: {formatTimestampToTime(props.horaInicio)}</Text>
        </>
    }

    function onClickIniciarAlmuerzo() {
        dispatch(iniciarAlmuerzo());
        onClose();
    }

    function onClickFinalizarAlmuerzo() {
        dispatch(finalizarAlmuerzo(props.idDescanso));
        onClose();
    }

    return (
        <>
            <Button
                disabled={props.idDescanso != null && props.horaFin != null}
                bg={bgAlmuerzo}
                _hover={{bg: hoverColor}}
                color='white'
                isLoading={isFetching}
                width="125px"
                height="125px"
                display="flex"
                alignItems="center"
                flexDirection="column"
                whiteSpace="normal"
                wordBreak="break-word"
                onClick={onOpen}>
                {stackAlmuerzo}
            </Button>
            <Modal isCentered size="xs" onClose={onClose} isOpen={isOpen}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>
                        Confirmar
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        ¿Está seguro que desea {textModal} su almuerzo?
                    </ModalBody>
                    <ModalFooter>
                        <Button bg='red.500' _hover={{bg: 'red.400'}} mr={3} onClick={onClose} color="white">
                            Cancelar
                        </Button>
                        <Button bg='cyan.650' _hover={{bg: 'cyan.600'}} color="white"
                                onClick={canIniciarDescanso(props.idDescanso, props.horaFin) ? onClickIniciarAlmuerzo : onClickFinalizarAlmuerzo}>
                            Aceptar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

/**
 * Verifica si el usuario puede iniciar un descanso
 * @param idDescanso    Id del descanso
 * @param horaFin       Timestamp en segundos de la fecha de finalizacion del descanso (puede ser null)
 */
function canIniciarDescanso(idDescanso: number, horaFin: number) {
    if (idDescanso == null) {
        return true;
    }

    if (horaFin != null) {
        return false;
    }

    return false;
}
