import {Box, Button, Flex, FormControl, FormLabel, Input, useToast} from "@chakra-ui/react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getErrorSelector, getIsAuthenticatedSelector, getIsFetchingSelector} from "../selectors/authSelector";
import {Redirect} from "react-router";
import {login} from "../slices/authSlice";
import {Image} from "@chakra-ui/react";

export function Login() {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errorShown, setErrorShown] = useState(false);

    const isAuthenticated = useSelector(getIsAuthenticatedSelector);
    const error = useSelector(getErrorSelector);
    const isFetching = useSelector(getIsFetchingSelector);

    const toast = useToast();

    const onUserChange = (event) => {
        setUsername(event.target.value);
    }

    const onLoginClick = async () => {
        dispatch(login({
            username: username,
            password: password
        }));
    }

    if (error && !errorShown) {
        toast({
            title: 'Error',
            description: error,
            status: "error"
        });

        setErrorShown(true);
    }

    if (isAuthenticated) {
        return <Redirect to='/home'/>
    }

    return (
        <Flex align="center" justify="center" height="100vh" bg="blue.800">
            <Box width="350px" borderWidth="1px" borderRadius="lg" px="20px" py="15px" bg="white">
                <Box align="center">
                    <Image
                        objectFit="cover"
                        src="/assets/centra-logo.png"
                        alt="Centracom"
                    />
                </Box>
                <FormControl>
                    <FormLabel>Usuario</FormLabel>
                    <Input type="text" value={username} onChange={onUserChange}/>
                </FormControl>

                <FormControl mt={2}>
                    <FormLabel>Contraseña</FormLabel>
                    <Input type="password" value={password} onChange={(event) => {
                        setPassword(event.target.value)
                    }}/>
                </FormControl>

                <Flex justify="end" mt={3}>
                    <Button mt={1} onClick={onLoginClick} isLoading={isFetching}>
                        Iniciar sesión
                    </Button>
                </Flex>
            </Box>
        </Flex>
    );
}
