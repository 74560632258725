import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@chakra-ui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isFetchingActividadesSelector} from "../selectors/actividadesSelector";
import {actividadesUsuario} from "../slices/actividadesSlice";
import {descansosUsuario} from "../slices/descansosSlice";
import {getIsFetchingSelector} from "../selectors/descansosSelector";

/**
 * Boton para refrescar los datos de descansos y actividades
 * @constructor
 */
export function RefreshDataBtn() {
    const dispatch = useDispatch();

    const isFetchingDescansos = useSelector(getIsFetchingSelector);
    const isFetchingActividades = useSelector(isFetchingActividadesSelector);
    const isFetchingData = isFetchingActividades || isFetchingDescansos;

    const refreshOnClick = () => {
        dispatch(descansosUsuario())
        dispatch(actividadesUsuario());
    };

    return <Button
      _hover={{bg: 'none'}}
      variant="ghost" onClick={refreshOnClick} isLoading={isFetchingData}>
        <FontAwesomeIcon icon={faSyncAlt} size="lg" />
    </Button>
}
