import {Route, Switch} from "react-router-dom";
import {Login} from "../pages/Login";
import {Home} from "../pages/Home";
import {Redirect} from "react-router";
import {Tareas} from "../pages/Tareas";
import {useSelector} from "react-redux";
import {getIsAuthenticatedSelector} from "../selectors/authSelector";
import {TareaDetalle} from "../pages/TareaDetalle";
import {Evaluacion} from "../pages/Evaluacion";

export function Routes() {
    const isAuthenticated = useSelector(getIsAuthenticatedSelector);

    if (isAuthenticated) {
        return <Switch>
            <Route path="/home"><Home/></Route>
            <Route path="/tareas/:idTarea/evaluacion"><Evaluacion/></Route>
            <Route path="/tareas/:idTarea"><TareaDetalle/></Route>
            <Route path="/tareas"><Tareas/></Route>
            <Redirect to="/home"/>
        </Switch>;
    }

    return (
        <Switch>
            <Route path="/login"><Login/></Route>
            <Redirect to="/login"/>
        </Switch>
    );
}
